var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [_c("formulario-facturacion", { staticClass: "pt-5 pb-5" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }