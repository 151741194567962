<template>
    <default-layout>
        <formulario-facturacion class="pt-5 pb-5"></formulario-facturacion>
    </default-layout>
</template>

<script>
import FormularioFacturacion from '../components/FormularioFacturacion.vue'

export default {
    components:{
        FormularioFacturacion
    },
    data() {
        return{

        }
    },
}
</script>